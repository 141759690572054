import React from 'react'
import './temporaryHome.css'
// import glowingman from "../../images/glowingman.png"
import glowingman1 from "../../images/flexclipBg1.mp4"

export default function TempHome2() {
  return (
    <div>
       <div style={{ position: "relative" }}>
          {/* <img className="tempHome1-home-image" alt="" src={ContactImage} /> */}
          <video 
            className="tempHome1-home-image" 
            src={glowingman1}
            autoPlay
            loop
            muted 
          />
        </div>
    </div>
  )
}
