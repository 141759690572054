import React from 'react'
// import Header from '../components/header'
import Footer from '../components/footer'
import AboutCIC from '../templates/Home/aboutCIC'
import TempHome2 from '../templates/Home/tempHome2'

export default function Temp_Home2() {
  return (
    <div>
        {/* <Header /> */}
        <TempHome2 />
        <AboutCIC />
        <Footer />
    </div>
  )
}
